<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1">
                <v-col sm="6" md="4" class="py-0" justify="center" align="center">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('pendings.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="6" align="end" class="py-0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="right white--text tools-view-height mx-1" color="amarillo" :to="'/create-appointment/national/' + m_type" v-permission:all="'appointment.create|n_pending.create'" style="max-width: 40px !important; min-width: 40px !important" >
                                <v-icon class="px-1" small>add</v-icon>
                            </v-btn>
                        </template>

                        <span>{{ $t('pendings.new') }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable class="custom_table_class" :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        headers(){
            //adding column operation in Spanish
            if(this.$store.state.language == 'es') return [
                { text: this.$t('pendings.id'), value: 'idIndex', class: 'text-uppercase' },
                { text: this.$t('pendings.client'), value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('pendings.date'), value: 'arrival_date', class: 'text-uppercase' },
                { text: this.$t('pendings.movement_type'), value: 'operation.description', class: 'text-uppercase' },
                { text: this.$t('pendings.container'), value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('pendings.description'), value: 'description', class: 'text-uppercase' },
                { text: this.$t('pendings.comments'), value: 'commentary', class: 'text-uppercase' },
                { text: this.$t('pendings.user'), value: 'user.name', class: 'text-uppercase' },
                { text: this.$t('pendings.actions'), value: 'actions', align: "center", class: 'text-uppercase' },
            ]
            //adding columnm operation in English
            else return [
                { text: this.$t('pendings.id'), value: 'idIndex', class: 'text-uppercase' },
                { text: this.$t('pendings.client'), value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('pendings.date'), value: 'arrival_date', class: 'text-uppercase' },
                { text: this.$t('pendings.movement_type'), value: 'operation.description1', class: 'text-uppercase' },
                { text: this.$t('pendings.container'), value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('pendings.description'), value: 'description', class: 'text-uppercase' },
                { text: this.$t('pendings.comments'), value: 'commentary', class: 'text-uppercase' },
                { text: this.$t('pendings.user'), value: 'user.name', class: 'text-uppercase' },
                { text: this.$t('pendings.actions'), value: 'actions', align: "center", class: 'text-uppercase' },
            ]
        } 
    },
    data(){
        return {
            m_type: this.$route.params.m_type, // income || outcome
            items: {
                clients: []
            },
            actions_income: [
                {
                    name: this.$t('pendings.preload'),
                    icon: {
                        color: 'accent',
                        icon: 'archive'
                    },
                    link: '/appointment-preload',
                    permission: 'appointment.update|detail_appointment.update|n_pending.update'
                },
                {
                    name: this.$t('pendings.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '/edit-appointment/',
                    permission: 'appointment.update|detail_appointment.update|n_pending.update'
                },
                // {
                //     name: this.$t('processed.files'),
                //     icon: {
                //         color: 'accent',
                //         icon: 'folder'
                //     },
                //     link: '/upload-files-national/',
                //     permission: 'appointment.file|n_pending.file'
                // },
            ],
            actions_outcome: [
            {
                    name: this.$t('pendings.preload'),
                    icon: {
                        color: 'accent',
                        icon: 'archive'
                    },
                    method: null,
                    permission: 'appointment.update|detail_appointment.update|n_pending.update'
                },
                {
                    name: this.$t('pendings.pickeo'),
                    icon: {
                        color: 'accent',
                        icon: 'qr_code_scanner'
                    },
                    method: null,
                    permission: 'appointment.update|detail_appointment.update|n_pending.update'
                },
                {
                    name: this.$t('pendings.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '/edit-appointment/',
                    permission: 'appointment.update|detail_appointment.update|n_pending.update'
                },
                // {
                //     name: this.$t('processed.files'),
                //     icon: {
                //         color: 'accent',
                //         icon: 'folder'
                //     },
                //     link: '/upload-files-national/',
                //     permission: 'appointment.file|n_pending.file'
                // },
            ],
            props: {
                search: '',
                items: [],
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            idToDelete: null,
        }
    },
    mounted(){
        this.index();
        if(this.m_type == "income") this.$store.state.module = "headers.n_pendings_income";
        else if(this.m_type == "outcome") this.$store.state.module = "headers.n_pendings_outcome";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointmentsby/Pendiente/1/' + this.m_type, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    const d2 = new Date((new Date()).toISOString().slice(0, 10));
                    response.data.data.forEach(x =>{
                        x = x.attributes;

                        x.idIndex = x.id;
                        x.arrival_date = (new Date(x.arrival_date)).toISOString().slice(0, 10);
                        const d1 = new Date(x.arrival_date);
                        
                        if(d1 > d2) x.dotColor = 'blue_dashboard';
                        else if(+d1 === +d2) x.dotColor = 'orange';
                        else x.dotColor = "red";
                        
                        if(x.operation.format_type == "Entrada"){
                            x['actions'] = JSON.parse(JSON.stringify(this.actions_income));
                            if(x.status == "Pendiente" || x.status == "En recinto" || x.status == "En rampa" || x.status == "Procesando") x['actions'][0].link = '/appointment-preload-income/' + x.idIndex + '/national/' + this.m_type;
                            else x['actions'][0].link = null;
                            x['actions'][1].link = '/edit-appointment/' + x.idIndex + '/national/' + this.m_type;
                            
                            if(x.files_status == "COMPLETED") x['actions'][1].icon.color = "success2";
                            else if(x.files_status == "SOME") x['actions'][1].icon.color = "warning";
                        } 
                        else if(x.operation.format_type == "Salida"){
                            // 2024-10-22 16:36:06
                            x['actions'] = JSON.parse(JSON.stringify(this.actions_outcome));
                            
                            if((x.warehouse.picking_required == false && x.status != "Pendiente de salida" && x.status != "Procesando") || (x.already_picked == null && x.warehouse.picking_required == true)) {
                                x['actions'][0].link = '/appointment-preload-outcome/' + x.idIndex + '/national/' + this.m_type;
                            }
                            
                            if(x.already_picked == null && x.warehouse.picking_required == true){
                                x['actions'][1].link = '/appointment-picking/' + x.idIndex;
                            }

                            x['actions'][2].link = '/edit-appointment/' + x.idIndex + '/national/' + this.m_type;
                            
                            if(x.files_status == "COMPLETED") x['actions'][2].icon.color = "success2";
                            else if(x.files_status == "SOME") x['actions'][2].icon.color = "warning";
                        } 
                        
                        x.parameters = x.idIndex;
                        this.props.items.push(x);
                    });
                    
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    },
    watch: {
        '$route.params.m_type': function() {
            location.reload();
        }
    }
}
</script>

<style lang="scss">

</style>