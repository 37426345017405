<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('relocations.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text" color="amarillo" :to="'/relocations/create'" v-permission="'product.create'">
                        {{ $t('relocations.new') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('relocations.date'), align: "center", value: 'created_at', class: 'text-uppercase' },
                { text: this.$t('relocations.customer'), align: "center", value: 'c_name', class: 'text-uppercase' },
                { text: this.$t('relocations.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('relocations.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('relocations.batch'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('relocations.reference'), align: "center", value: 'reference', class: 'text-uppercase' },
                { text: this.$t('relocations.origin'), align: "center", value: 'o_name', class: 'text-uppercase' },
                { text: this.$t('relocations.destination'), align: "center", value: 'd_name', class: 'text-uppercase' },
                { text: this.$t('relocations.user'), align: "center", value: 'u_name', class: 'text-uppercase' }
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            idRelocation: '',
            actions: [
                {
                    name: this.$t('relocations.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                    permission: "relocation.show"
                }
            ],
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.relocations";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'relocations?warehouse=' + this.$store.state.warehouseId, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.data.forEach(x => {
                        x = x.attributes;
                        x.created_at = x.created_at.split('T')[0] + ' - ' + x.created_at.split('T')[1].substring(0, 8);
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x.actions[0].link = '/relocations/details/' + x.id;
                        this.props.items.push(x);
                    })

                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(relocation){
            this.modalDelete = true;
            this.idRelocation = relocation.id;
        },
        deleteRelocation(){
            console.log(this.idRelocation);
        },
        update(relocation){
            console.log(relocation);
        },
    }
}
</script>

<style>

</style>