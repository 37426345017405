<template>
	<v-row justify="center" align="center" class="mx-8">
		<v-container>
			<v-list lines="two" subheader>
				<v-row>
					<v-col cols="4">
						<v-checkbox v-model="selected.opcions.pallets" color="amarillo" :label="$t('edit_appointment.rfid.pallets')"></v-checkbox>
					</v-col>
					<!-- <v-col cols="4">
						<v-checkbox v-model="selected.opcions.boxes" color="amarillo" label="Cajas"></v-checkbox>
					</v-col> -->
					<v-col cols="4" v-if="tag_per_product">
						<v-checkbox v-model="selected.opcions.tags" color="amarillo" :label="$t('edit_appointment.rfid.tags')"></v-checkbox>
					</v-col>
					<v-col cols="4">
						<v-checkbox v-model="selected.opcions.rfid_icon" color="amarillo" :label="$t('edit_appointment.rfid.rfid_icon')"></v-checkbox>
					</v-col>
					
				</v-row>
				<v-row>
					<!-- <v-col cols="4">
						<v-checkbox v-model="selected.opcions.only_new" color="amarillo" :label="$t('edit_appointment.rfid.only_new')"></v-checkbox>
					</v-col> -->
					<v-col cols="4">
						<v-select :label="$t('edit_appointment.rfid.printer')" :items="printers" v-model="printer_selected"></v-select>
					</v-col>
					<v-col cols="2">
						<v-btn class="white--text" color="amarillo" @click="print()"> {{ $t('edit_appointment.rfid.print') }} </v-btn>
					</v-col>
				</v-row>
			</v-list>
			<br>
			<v-divider></v-divider>
			<br>
			<v-list lines="two" subheader>
				<v-tabs v-model="tab" background-color="transparent" color="basil" grow>
					<v-tab v-for="t in tabs" :key="t">
						{{ t }}
					</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item :key="$t('edit_appointment.rfid.pallet')">
						<v-card>
							<v-data-table :headers="headers_pallets" :items="items.pallets" item-key="id" show-select
								v-model="selected.pallets" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
								class="elevation-1"></v-data-table>
						</v-card>
					</v-tab-item>
					<!-- <v-tab-item key="Cajas">
						<v-card>
							<v-data-table :headers="headers_boxes" :items="items.boxes" item-key="id" show-select
								v-model="selected.boxes" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
								class="elevation-1"></v-data-table>
						</v-card>
					</v-tab-item> -->
					<v-tab-item :key="$t('edit_appointment.rfid.units')">
						<v-card>
							<v-data-table :headers="headers_tags" :items="items.tags" item-key="id" show-select
								v-model="selected.tags" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
								class="elevation-1"></v-data-table>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-list>
		</v-container>
	</v-row>
</template>

<script>
export default {
	props: [
		'items',
		'tag_per_product'
	],
	data() {
		return {
			rfid_dialog: true,
			tab: null,
			tabs: [this.$t('edit_appointment.rfid.pallets'), this.$t('edit_appointment.rfid.units')],
			selected: {
				pallets: [],
				boxes: [],
				tags: [],
				opcions: {
					pallets: false,
					boxes: false,
					tags: false,
					only_new: false,
					rfid_icon: false
				}
			},
			printers: [],
			printer_selected: "",
		}
	},
	computed: {
		headers_pallets() {
			return [
				{ text: 'ID', align: "center", value: 'id', },
				{ text: 'EPC', align: "center", value: 'epc', },
				{ text: this.$t('edit_appointment.rfid.pallet'), align: "center", value: 'pallet', },
				{ text: this.$t('edit_appointment.rfid.location'), align: "center", value: 'ubicacion', },
				// { text: '# Cajas', align: "center", value: 'cajas', },
				{ text: "#" + this.$t('edit_appointment.rfid.units'), align: "center", value: 'piezas', },
			]
		},
		headers_tags() {
			return [
				{ text: 'ID', align: "center", value: 'id', },
				{ text: 'EPC', align: "center", value: 'epc', },
				{ text: this.$t('edit_appointment.rfid.product'), align: "center", value: 'product', },
				{ text: this.$t('edit_appointment.rfid.batch'), align: "center", value: 'batch', },
				{ text: this.$t('edit_appointment.rfid.expiration'), align: "center", value: 'reference', },
				{ text: this.$t('edit_appointment.rfid.location'), align: "center", value: 'location', },
				{ text: this.$t('edit_appointment.rfid.pallet'), align: "center", value: 'pallet', },
				// { text: 'Caja', align: "center", value: 'box', },
			]
		},
	},
	mounted() {
		this.getPrinters();
		this.index();
	},
	methods: {
		getPrinters() {
			this.$http.get(this.$store.state.printerServiceApi + 'printers/')
				.then((response) => {
					this.printers = response.data.map(p => { return p.name })
				}).catch(error => console.log(error))
		},
		show() {
			console.log("se muestra el componente")
			this.rfid_dialog = true
		},
		hide() {
			console.log("se oculta el componente")
			this.rfid_dialog = false
		},
		index(){
			console.log(this.selected)
			var t = this;
			this.$http.post(this.$store.state.chronosApi + 'rfid/get_tags', this.selected)
			.then((response) => {
				t.items.pallets = response.data.items.pallets;
				t.items.products = response.data.items.products;
			}).catch(error => console.log(error))
		},
		print() {
			console.log(this.selected)
			var t = this;
			this.$http.post(this.$store.state.chronosApi + 'rfid/get_tags', this.selected)
				.then((response) => {
					console.log("aqui es donde se imprime")
					console.log(response)
					var print_info = {
						printer: t.printer_selected,
						data: {
							pallets: response.data.items.pallets,
							details: response.data.items.boxes,
							products: response.data.items.products,
						},
						print_type: {
							tag: t.selected.opcions.tags,
							box: t.selected.opcions.boxes,
							pallet: t.selected.opcions.pallets,
							rfid_icon: t.selected.opcions.rfid_icon
						}
					}
					console.log(print_info)
					this.$http.post(this.$store.state.printerServiceApi + 'print/', print_info)
						.then((response) => {
							console.log("aqui es donde se imprime")
							console.log(response)
						}).catch(error => console.log(error))
				}).catch(error => console.log(error))
			// var print_info = {}
			// this.$http.post(this.$store.state.printerServiceApi + 'print/', print_info)
			//     .then((response) => {
			//       console.log("aqui es donde se imprime")
			//       console.log(response)
			//     }).catch(error => console.log(error))
		}
	},

}
</script>

<style></style>