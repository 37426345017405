<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_product.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.client_id" item-value="id" item-text="name" :items="clients" :label="$t('create_product.client')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.sku" :label="$t('create_product.sku')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.barcode" :label="$t('create_product.barcode')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.description" :label="$t('create_product.description')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="!client_hd">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.category_id" :items="categories" item-value="id" item-text="name" :label="$t('create_product.category')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="!client_hd">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.storage_policy" :label="$t('create_product.refill_alert')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="!client_hd">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.unit" :items="units" :label="$t('create_product.unit')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="!client_hd">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.size_unit" :items="size_units" :label="$t('create_product.size_unit')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="!client_hd">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.height" :rules="rules_required" :label="$t('create_product.height')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="!client_hd">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.length" :rules="rules_required" :label="$t('create_product.length')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="!client_hd">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.width" :rules="rules_required" :label="$t('create_product.width')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="!client_hd">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.weight" :label="$t('create_product.weight')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pb-2 my-0" justify="center" align="center">
                            <v-col cols="4" class="px-3 py-0">
                                <span >{{ $t('create_product.product_national') }}:</span>
                            </v-col>
                            <v-col cols="2" class="px-3 py-0" justify="end" align="end">
                                <v-btn class="radio-left" v-bind:color="product.is_national == false ? 'info' : 'white'" @click="product.is_national = false">{{ $t('create_product.no') }}</v-btn>
                                <v-btn class="radio-right" v-bind:color="product.is_national == true ? 'info' : 'white'" @click="product.is_national = true">{{ $t('create_product.yes') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_product.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'product.create'">
                                {{ $t('create_product.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            product: {
                sku: '',
                description: '',
                storage_policy: 0,
                quantity: 0,
                existence: '',
                cost: '',
                weight: '',
                unit: '',
                is_national: true,
                client_id: '',
                category_id: '',
                barcode: '', 
                height: '', 
                length : '', 
                width: '',
                size_unit: '',
                product_type: '',
                // quantity_per_box: '',
                // quantity_per_pallet: '',
                warehouse_id: this.$store.state.warehouseId
            },
            categories: [],
            clients: [],
            size_units: ["CM/KG", "IN/LB"],
            units: [
                "KILO",
                "GRAMO",
                "METRO LINEAL",
                "METRO CUADRADO",
                "METRO CÚBICO",
                "PIEZA",
                "CABEZA",
                "LITRO",
                "PAR",
                "KILOWATT",
                "MILLAR",
                "JUEGO",
                "KILOWATT/HORA",
                "TONELADA",
                "BARRIL",
                "GRAMO NETO",
                "DECENAS",
                "CIENTOS",
                "DOCENAS",
                "CAJA",
                "BOTELLA",
                "POUNDS",
                "FEET"
            ],
            client_hd: false,
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_product";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'clientsIndex/2', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data
                response.data.forEach(x => {
                    x = x.attributes;
                    if(x.client_type == "HD") this.clients.push({ id: x.id, name: x.name + " (" + this.$t("create_product.client_hd") + ")", type: x.client_type });
                    else this.clients.push({ id: x.id, name: x.name, type: x.client_type });
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            this.$http.get(this.$store.state.chronosApi + 'categories', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.forEach(x => {
                    this.categories.push({id: x.id, name: x.name})
                }) 
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.product.existence = this.product.quantity;

            const formVal = this.$refs.form.validate();

            if(!formVal){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('create_product.form_invalid')
                }
                this.$store.state.loaderProps.visible = false;

                return;
            }

            this.$http.post(this.$store.state.chronosApi + 'products', this.product,{headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status == 201){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('create_product.responses.success')
                    }
                    this.$router.push({ name: "ProductsIndex" });
                }  
            }).catch((err) => {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: err.response.data.message
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        'product.client_id': function(){
            this.client_hd = false;

            if(this.clients.find(x => x.id == this.product.client_id).type == "HD") {
                this.product.product_type = "HD";
                this.client_hd = true;
                this.product.unit = "PIEZA";
                this.product.category_id = this.categories.find(x => x.name == "HEAVY DUTY").id;
            }
            else {
                this.product.product_type = "DEFAULT";
            }
        }
    }
}
</script>

<style lang="css">
</style>