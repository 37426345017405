<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1">
                <v-col cols="2" class="py-0">
                    <v-autocomplete 
                    :prefix="`${$t('customs_patches.reports_discharges.year')} |`"
                    v-model="year" 
                    class="py-0"
                    @change="getDischargeReport({year:year})"
                    :items="years"></v-autocomplete>
                </v-col>

                <v-col sm="2" md="2" class="py-0 px-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('customs_patches.customs_documents.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="padding-left: 10px;border: rgb(217, 217, 217) 1px solid; border-radius:5px;min-height: 40px;"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
            
                <v-col cols="4" align="end" class="py-0 px-0">
                    <v-row justify="end" align="end" class="mr-2 mt-0">
                        <v-btn class="right white--text" color="amarillo" @click="modal_annex_30 = true">
                            {{ $t('customs_patches.reports_discharges.annex30') }}
                        </v-btn>  

                        <v-btn class="right white--text mr-1 ml-2" color="amarillo" @click="dischargesReportExport({year:year})">
                            {{ $t('customs_patches.reports_discharges.export') }}
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable  :custom_columns="['customs_document']" :props="{headers:headers, items: discharge.data, search:props.search}" @methodHandler="methodHandler">
                                <template v-slot:item.customs_document="{ item }">
                                    <v-icon v-if="item.existence < 0" small v-on="on" color="red"> fiber_manual_record </v-icon>
                                    {{ item.customs_document }} 
                                </template>
                            </datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modal_annex_30" persistent max-width="90%" style="width: 500px !important;">
                <v-card style="width:500px !important">
                    <v-card-title class="justify-center mt-4">
                        <v-row align="center" justify="center" class="my-2">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('customs_patches.reports_discharges.annex30')}}</label>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="annex_30_values.cve" :items="keys" :label="$t('customs_patches.reports_discharges.key')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="annex_30_values.bimester" :items="bimesters" :label="$t('customs_patches.reports_discharges.bimester')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-autocomplete :label="$t('customs_patches.reports_discharges.year')" v-model="annex_30_values.year" class="py-0" :items="years"></v-autocomplete>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modal_annex_30 = false">{{ $t('customs_patches.reports_discharges.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="getAnnex30({annex: annex_30_values}); modal_annex_30 = false;">
                                {{ $t('customs_patches.reports_discharges.save') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Utils from '../../../helpers/Utils';

export default {
    computed: {
        ...mapGetters('customs_patches_reports', [
            'discharge',
            'dischargeExport',
            'annex'
        ]),
        headers(){
            return [
                { text: this.$t('customs_patches.reports_discharges.table.customs_document'), align: "center", value: 'customs_document', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.cve_doc'), align: "center", value: 'cve_doc', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.customs_document_date'), align: "center", value: 'customs_document_date', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.invoice_number'), align: "center", value: 'invoice_number', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.part_number'), align: "center", value: 'part_number', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.description'), align: "center", value: 'description', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.fraction'), align: "center", value: 'fraction', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.invoice_quantity'), align: "center", value: 'invoice_quantity', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.umc'), align: "center", value: 'umc', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.discharged_quantity'), align: "center", value: 'discharged_quantity', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.existence'), align: "center", value: 'existence', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.outcome_customs_document'), align: "center", value: 'outcome_customs_document', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.outcome_cve_doc'), align: "center", value: 'outcome_cve_doc', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_discharges.table.outcome_customs_document_date'), align: "center", value: 'outcome_customs_document_date', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            year:null,
            years: Utils.generateArrayOfYears(6),
            dateRange: {
                start_date: Utils.getStartDate(), 
                end_date: new Date().toISOString().split('T')[0]
            },
            keys: Utils.getOutcomeKeys(),
            bimesters: Utils.getBimesters(),
            annex_30_values: {
                cve: "",
                bimester: "",
                year: new Date().getFullYear()
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
            },
            modal_annex_30: false
        }
    },
    watch: {
        discharge: {
            handler(val){
                this.$store.state.loaderProps.visible = val.loading;
            },
            deep: true
        },
        export: {
            handler(val){
                this.$store.state.loaderProps.visible = val.loading;
            },
            deep: true
        },
        annex: {
            handler(val){
                this.$store.state.loaderProps.visible = val.loading;
            },
            deep: true
        },
    },
    mounted(){
        this.$store.state.module = "headers.reports_discharges";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        ...mapActions('customs_patches_reports', [
            'getDischargeReport',
            'dischargesReportExport',
            'getAnnex30'
        ])
    }
}
</script>

<style>

</style>