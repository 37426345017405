<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1">
                <v-col sm="3" md="4" class="py-0" justify="center" align="center">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('no_annex.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-col sm="3" md="4" class="py-0" justify="center" align="center">
                    <v-autocomplete v-model="filters.format_type" :label="$t('no_annex.format_type')" item-value="value" item-text="text" :items="format_types"></v-autocomplete>
                </v-col>
                <v-col sm="3" md="4" class="py-0" justify="center" align="center">
                    <v-autocomplete v-model="filters.year" :label="$t('no_annex.year')" :items="years"></v-autocomplete>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable class="custom_table_class" :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3>
                                    {{ $t('no_annex.modal.delete') }}
                                </h3>
                        </v-row>
                        <v-btn color="primary darken-1" small icon @click="modalDelete = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('no_annex.delete_message1') }} {{ $t('no_annex.delete_message2') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('no_annex.no') }}</v-btn>
                            <v-btn color="error" @click="deleteAppointment" v-permission:all="'appointment.delete|detail_appointment.delete|i_without_annex.delete'">
                                {{ $t('no_annex.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        headers(){
            //adding column operation in Spanish
            if(this.$store.state.language == 'es') return [
                { text: this.$t('no_annex.id'), value: 'idIndex', class: 'text-uppercase' },
                { text: this.$t('no_annex.client'), value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('no_annex.date'), value: 'arrival_date', class: 'text-uppercase' },
                { text: this.$t('no_annex.movement_type'), value: 'operation.description', class: 'text-uppercase' },
                { text: this.$t('no_annex.container'), value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('no_annex.invoice'), value: 'bill_of_lading', class: 'text-uppercase' },
                { text: this.$t('no_annex.petition'), value: 'petition', class: 'text-uppercase' },
                { text: this.$t('no_annex.user'), value: 'user.name', class: 'text-uppercase' },
                { text: this.$t('no_annex.actions'), value: 'actions', align: "center", class: 'text-uppercase' },
            ]
            //adding columnm operation in English
            else return [
                { text: this.$t('no_annex.id'), value: 'idIndex', class: 'text-uppercase' },
                { text: this.$t('no_annex.client'), value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('no_annex.date'), value: 'arrival_date', class: 'text-uppercase' },
                { text: this.$t('no_annex.movement_type'), value: 'operation.description1', class: 'text-uppercase' },
                { text: this.$t('no_annex.container'), value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('no_annex.invoice'), value: 'bill_of_lading', class: 'text-uppercase' },
                { text: this.$t('no_annex.petition'), value: 'petition', class: 'text-uppercase' },
                { text: this.$t('no_annex.user'), value: 'user.name', class: 'text-uppercase' },
                { text: this.$t('no_annex.actions'), value: 'actions', align: "center", class: 'text-uppercase' },
            ]
        } 
    },
    data(){
        return {
            items: {
                clients: []
            },
            actions: [
                {
                    name: this.$t('no_annex.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                    permission:'appointment.show|detail_appointment.show|i_without_annex.show'
                },
                {
                    name: this.$t('no_annex.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '',
                    permission:'appointment.update|detail_appointment.update|i_without_annex.update'
                },
                {
                    name: this.$t('no_annex.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission:'appointment.delete|detail_appointment.delete|i_without_annex.delete'
                },
                {
                    name: this.$t('no_annex.files'),
                    icon: {
                        color: 'accent',
                        icon: 'folder'
                    },
                    link: '/files',
                    permission: 'appointment.file|i_without_annex.file'
                },
                {
                    name: this.$t('no_annex.download_pdf'),
                    icon: {
                        color: 'accent',
                        icon: 'sticky_note_2'
                    },
                    method: 'download',
                    permission: 'i_pdf.show'
                }
            ],
            props: {
                search: '',
                items: [],
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            format_types: [
                {value: "Income", text: this.$t("no_annex.income")},
                {value: "Outcome", text: this.$t("no_annex.outcome")},
            ],
            filters: {
                format_type: "Income",
                year: new Date().getFullYear(),
            },
            years: this.$store.state.years_catalog,
            modalDelete: false,
            idToDelete: null,
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.no_annex";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointmentsby/PendienteAnexo/0?format_type=' + this.filters.format_type + '&year=' + this.filters.year, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    const d2 = new Date((new Date()).toISOString().slice(0, 10));
                    response.data.data.forEach(x =>{
                        x = x.attributes;
                        
                        x.idIndex = x.id;
                        x.arrival_date = (new Date(x.arrival_date)).toISOString().slice(0, 10);
                        const d1 = new Date(x.arrival_date);
                        
                        if(d1 > d2) x.dotColor = 'blue_dashboard';
                        else if(+d1 === +d2) x.dotColor = 'orange';
                        else x.dotColor = "red";
                        
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x['actions'][0].link = '/appointment-details/' + x.idIndex + '/international';
                        x['actions'][1].link = '/edit-appointment/' + x.idIndex + '/international';            
                        x['actions'][3].link = "/upload-files/" + x.idIndex;
                        
                        if(x.document_management == null || x.document_management == undefined) x['actions'][3].icon.color = 'azul';
                        else {
                            var required_files = typeof x.document_management.required_files == "string" ? JSON.parse(x.document_management.required_files) : x.document_management.required_files
                            var count_rf = 0
                            for (var key in required_files) {
                                if(required_files[key] == true) count_rf++; 
                            }
                            if(count_rf > x.documents_blobs.length) x['actions'][3].icon.color = 'warning';
                            else if(count_rf <= x.documents_blobs.length) x['actions'][3].icon.color = 'success2';
                            
                            if(x.document_management.inconvenient == true) x['actions'][3].icon.color = 'purple'
                        }

                        if(x.operation.description.toLowerCase() == 'crossdock documental entrada' && (x.status.toLowerCase() == 'completo' || x.status.toLowerCase() == 'pendiente anexo24') && (x.outcome_appointment == null || x.outcome_appointment == undefined)){
                            x.actions.push({
                                name: this.$t('no_annex.output_format'),
                                icon: {
                                    color: 'accent',
                                    icon: 'add'
                                },
                                link: '/outcome_appointment/' + x.id,
                            });
                        }

                        x.parameters = x.idIndex;
                        this.props.items.push(x);
                    });
                    
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('no_annex.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(x){
            this.idToDelete = x;
            this.modalDelete = true;
        },
        download(i){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointments/' + i + "/pdf", {headers: {Authorization: lsToken}, responseType: 'arraybuffer'})
            .then((response) => {
                this.forceFileDownloadHistory(response, i)
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        deleteAppointment(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'appointments/' + this.idToDelete, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.modalDelete = false;
                    this.index();
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('no_annex.responses.deleted')
                        }
                    }
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch(() => {
                this.$store.state.loaderProps.visible = false;
                this.modalDelete = false;
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('no_annex.responses.error')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        forceFileDownloadHistory(response, id){
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            this.$store.state.loaderProps.visible = false
            link.href = url
            link.setAttribute('download', 'Appointment - ' + id + '.pdf')
            document.body.appendChild(link)
            link.click()
        },
    },
    watch:{
        'filters.format_type': function (){
            this.index();
        },
        'filters.year': function (){
            this.index();
        }
    }
}
</script>

<style lang="css">

</style>