<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('products.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text mx-3 tools-view-height" outlined color="amarillo" @click="modalImport = true">
                        {{ $t('products.import') }}
                        <v-icon class="px-1" small>cloud_upload</v-icon>
                    </v-btn>
                    <v-btn class="right white--text" color="amarillo" :to="'/products/create'" v-permission="'product.create'">
                        {{ $t('products.new') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalImport" persistent max-width="90%" min-width="500px">
                <v-card>
                    <v-card-title class="justify-center">
                        <v-spacer></v-spacer>
                        {{ $t('products.import_products') }}
                        <v-spacer></v-spacer>
                        <v-btn color="amarillo" small icon @click="stepper2 = 1;modalImport = false"><v-icon small>close</v-icon></v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-stepper v-model="stepper2">
                            <v-stepper-header>
                                <v-stepper-step :complete="stepper2 > 1" step="1" color="amarillo">{{$t('products.select_products_file')}}</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step :complete="stepper2 > 2" step="2" color="amarillo">{{ $t('products.verify_data') }}</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step :complete="stepper2 > 4" step="4" color="amarillo">{{ $t('products.results') }}</v-stepper-step>
                                <v-divider></v-divider>
                            </v-stepper-header>

                            <v-stepper-items>
                                <v-stepper-content step="1" class="mx-0 px-0">
                                    <v-card flat class="mb-5">
                                        <v-form ref="form2" lazy-validation>
                                            <v-row class="mx-3 px-0" justify="center" align="center">
                                                <v-col cols="10">
                                                    <v-file-input v-model="inputFile2" accept=".csv" color="amarillo" :label="$t('products.select_products_file')"></v-file-input>
                                                </v-col>
                                                <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepper2 = 3">
                                                            <v-icon aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('products.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card>
                                    
                                    <div class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-btn class="right mr-2 white--text" color="amarillo" @click="uploadProducts" >
                                            {{ $t('products.next') }}
                                        </v-btn>
                                    </div>
                                </v-stepper-content>

                                <v-stepper-content step="2">
                                    <v-card flat class="mb-5">
                                        <v-card-text class="my-0 py-0">
                                            <p class="my-0 py-0">{{readRows2.length}} {{ $t('products.products_in_file') }}</p>
                                        </v-card-text>
                                        <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ th }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, i) in readRows2" :key="i">
                                                        <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>

                                    <div class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []">{{ $t('products.go_back') }}</v-btn>
                                        <v-btn color="amarillo" class="white--text" @click="saveProducts">
                                            {{ $t('products.save_data') }}
                                        </v-btn>
                                    </div>
                                </v-stepper-content>

                                <v-stepper-content step="3">
                                    <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                    <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                    <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                    <p class="py-0 my-0">4.- El archivo de productos debe ser formato CSV.</p> 
                                    <p>5.- Si el campo presenta la leyenda 'Opcional' este campo puede ir vacío.</p> 
                                    <p class="py-0 my-0">Columnas: </p>
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                   <th class="text-left" style="padding: 0 5px !important;">sku</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">description</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">client</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">unit</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">category</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">restock_alert</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">weight</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">is_national</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">barcode</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">height</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">length</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">width</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">size_unit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>product 1</td>
                                                    <td>product description</td>
                                                    <td>Shop Online</td>
                                                    <td>Kilo</td>
                                                    <td>Juguetes</td>
                                                    <td>10</td>
                                                    <td>0.5</td>
                                                    <td>0</td>
                                                    <td>1000ABC000</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>CM/KG | IN/LB</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepper2 = 1">
                                            {{ $t('products.go_back') }}
                                        </v-btn>
                                    </v-row>
                                </v-stepper-content>

                                <v-stepper-content step="4">
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                   <th class="text-left" style="padding: 0 5px !important;">Message</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in results" :key="i">
                                                    <td>{{ item.message }}</td>
                                                    <td>{{ item.status }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepper2 = 1; index(); results = []; modalImport = false">
                                            {{ $t('products.finish') }}
                                        </v-btn>
                                    </v-row>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card-text>
                </v-card>
            </v-dialog>
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('products.sku'), align: "center", value: 'sku', class: 'text-uppercase', width: "10%" },
                { text: this.$t('products.description'), align: "center", value: 'description', class: 'text-uppercase', width: "20%" },
                { text: this.$t('products.unit'), align: "center", value: 'unit', class: 'text-uppercase', width: "10%" },
                { text: this.$t('products.client'), align: "center", value: 'client', class: 'text-uppercase', width: "10%" },
                { text: this.$t('products.available_stock'), align: "center", value: 'available_stock', class: 'text-uppercase', width: "10%" },
                { text: this.$t('products.reserved_stock'), align: "center", value: 'reserved_stock', class: 'text-uppercase', width: "10%" },
                { text: this.$t('products.existence_stock'), align: "center", value: 'existence_stock', class: 'text-uppercase', width: "10%" },
                { text: this.$t('products.damaged_stock'), align: "center", value: 'damaged_stock', class: 'text-uppercase', width: "10%" },
                { text: this.$t('products.actions'), align: "center", value: 'actions', class: 'text-uppercase', width: "10%" },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalImport: false,
            inputFile2: [],
            readFile2: '',
            readRows2: [],
            stepper2: 1,
            sTHeaders2:'',
            idProduct: '',
            actions: [
                {
                    name: this.$t('products.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                    permission: "product.show"
                },
                {
                    name: this.$t('products.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '',
                    permission: "product.update"
                },
                {
                    name: this.$t('products.refurbish'),
                    icon: {
                        color: 'accent',
                        icon: 'recycling'
                    },
                    link: '/products/refurbish/1',
                    permission: "refurbish.index"
                }
            ],
            props: {
                search: '',
                items: [],
            },
            results: [],
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.products";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'products', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x =>{
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x.actions[0].link = 'products/details/' + x.id;
                    x.actions[1].link = 'products/edit/' + x.id;
                    x.actions[2].link = 'products/refurbish/' + x.id;
                    var stock = {
                        sku: x.attributes.sku,
                        available_stock: 0,
                        existence_stock: 0,
                        damaged_stock: 0,
                        description: x.attributes.description,
                        client: x.attributes.client.name,
                        actions: x.actions,
                        parameters: x.attributes,
                        unit: x.attributes.unit
                    };

                    x.attributes.stock.forEach( y => {
                        y = y.attributes;
                        if(y.warehouse_id == this.$store.state.warehouseId) {
                            stock.available_stock = (y.quantity - y.reserved);
                            stock.reserved_stock = y.reserved;
                            stock.existence_stock = y.quantity;
                            stock.damaged_stock = y.damaged;
                            return;
                        }
                    })
                    this.props.items.push(stock);
                });
                this.$store.state.loaderProps.visible = false;
                
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        uploadProducts(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile2);
            }
        },
        createInput2(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows2 = result.split('\r\n');
                    this.sTHeaders2 = this.readRows2[0].split(',');
                    this.readRows2.shift();
                    this.stepper2 = 2;
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveProducts(){
            this.$store.state.loaderProps.visible = true;
            const products = [];
            if(this.readRows2.length > 0){
                this.readRows2.forEach((x) => {
                    const num = x.split(',');
                    products.push({
                        'sku': num[0],
                        'description': num[1],
                        'client': num[2],
                        'unit': num[3],
                        'category': num[4],
                        'restock': num[5],
                        'weight': num[6],
                        'national': num[7],
                        'barcode': num[8],
                        'height': num[9],
                        'length': num[10],
                        'width': num[11],
                        'size_unit': num[12]
                    });
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'importProducts', {products: products}, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.data.status === 200){
                        this.stepper2 = 4;
                        this.sTHeaders2 = [];
                        this.readRows2 = [];
                        this.inputFile2 = null;
                        this.results = response.data.results;
                    }
                })
                .catch((error) => {
                    console.log(error.response.data.message)
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: "error " + error.response.data.message
                    };
                })
                .finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('products.validations.select_file_first')
                };
            }
        }
    }
}
</script>

<style>

</style>