<template>
     <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="3" md="3" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fulfillment_eon_returned.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <!-- <v-col cols="2" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height white--text" outlined color="amarillo" @click="modalUploadreturn = true">
                        {{ $t('fulfillment_eon_returned.upload_returned') }}
                        <v-icon class="material-icons-outlined px-1" small>cloud_upload</v-icon>
                    </v-btn>
                </v-col> -->
                <v-col cols="9" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="modalUploadIncidences = true">
                        {{ $t('fulfillment_eon_returned.upload_returns') }}
                        <v-icon class="material-icons-outlined px-1" small>cloud_upload</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="uon"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillment_eon_send.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>

                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_send.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.order_number") }}</label> <label>{{ this.orderToShow.unique_order_number }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.shipping_service") }}</label> <label>{{ this.orderToShow.shipping_service }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.date") }}</label> <label>{{ this.orderToShow.created_at }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.customer") }}</label> <label>{{ this.orderToShow.customer }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.buyer") }}</label> <label>{{ this.orderToShow.buyer }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_send.products') }}</label></div>
                    <span>
                        <div v-for="(product, i) of orderToShow.products" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.sku }}</label> <label>{{ product.quantity }}</label></div>
                    </span>
                
                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn  color="amarillo" class="white--text" @click="modalDetail = false">
                                {{ $t('fulfillment_eon_send.go_back') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalUploadIncidences" persistent max-width="90%" >
                <v-card style="min-width: 500px">
                <v-card-title class="justify-center">
                    {{ $t('fulfillment_eon_returned.upload_returns') }}
                </v-card-title>
                    <v-stepper v-model="stepper2">
                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-0 px-0">
                                <v-card flat class="mb-5">
                                    <v-form ref="form2" lazy-validation>
                                        <v-row class="mx-0 px-0" justify="center" align="center">
                                            <v-col cols="10">
                                                <v-file-input v-model="inputFile2" accept=".csv" :label="$t('fulfillment_eon_returned.returns_file')" append-outer-icon="attach_file" prepend-icon="null" :rules="rules_required"></v-file-input>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" v-bind="attrs" v-on="on" @click="stepper2 = 4">
                                                            <v-icon class="material-icons-outlined" aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('fulfillment_eon_returned.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                <v-row class="mx-2 px-5 mx-5 py-0" justify="end" align="end">
                                    <v-col cols="2" justify="end" align="end" class="mr-8">
                                        <v-btn text  @click="modalUploadIncidences = false;" >
                                            {{ $t('fulfillment_eon_returned.cancel') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" justify="end" align="end">
                                        <v-btn class="white--text" color="amarillo" @click="uploadReturns" >
                                            {{ $t('fulfillment_eon_returned.load') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5">
                                    <v-card-text class="my-0 py-0">
                                        <p class="my-0 py-0">{{readRows2.length}} {{ $t('fulfillment_eon_returned.orders_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ th }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows2" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="px-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []">{{ $t('fulfillment_eon_returned.cancel') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text px-3" @click="saveFile">
                                        {{ $t('fulfillment_eon_returned.save_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="4">
                                    <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                    <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                    <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                    <p class="py-0 my-0">4.- El archivo de productos debe ser formato CSV.</p> 
                                    <p class="py-0 my-0">5.- El formato de la fecha debe ser AAAA-MM-DD.</p> 
                                    <p class="py-0 my-0">Columnas: </p>
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                   <th class="text-left" style="padding: 0 5px !important;">Orden</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">Fecha</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">Rack</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>O-00001</td>
                                                    <td>2025-01-01</td>
                                                    <td>10001</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepper2 = 1">
                                            {{ $t('products.go_back') }}
                                        </v-btn>
                                    </v-row>
                                </v-stepper-content>
                            
                            <v-stepper-content step="3">
                                <v-card flat class="mb-5">
                                    <v-simple-table v-if="this.responses.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillment_eon_returned.order') }}</th>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillment_eon_returned.status') }}</th>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillment_eon_returned.message') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in responses" :key="i">
                                                    <td>{{ item.order }}</td>
                                                    <td>{{ item.status }}</td>
                                                    <td>{{ item.message }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-btn class="px-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []; modalUploadIncidences = false;">{{ $t('fulfillment_eon_returned.close') }}</v-btn>
                                </div>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalRestore" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillment_eon_send.restore_modal.restore') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillment_eon_send.restore_modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalRestore = false">{{ $t('fulfillment_eon_send.no') }}</v-btn>
                            <v-btn color="error" @click="restoreOrder">
                                {{ $t('fulfillment_eon_send.restore_yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
        </v-container>        
    </div>
</template>

<script>
export default {
	computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('fulfillment_eon_returned.order'), value: 'uniqueOrderNumber' },
                { text: this.$t('fulfillment_eon_returned.customer'), value: 'client.name' },
                // { text: this.$t('fulfillment_eon_returned.shipping_number'), value: 'shipmentNumber' },
                { text: this.$t('fulfillment_eon_returned.returned'), value: 'returnedAt' },
                { text: this.$t('fulfillment_eon_returned.actions'), value: 'actions' },
            ];
        }
    },
    data(){
        return {
            props: {
                search: '',
                items: [],
            },
            modalDelete: false,
            modalDetail: false,
            modalRestore: false,
            modalUploadIncidences: false,
            orderToDelete: '',
            orderToShow: '',
            inputFile2: [],
            readFile2: '',
            readRows2: [],
            stepper2: 1,
            sTHeaders2:'',
            responses: [],
            modalUploadreturn: false,
            menu: false,
            returned: {
                unique_order_number: "",
                warehouse_id: this.$store.state.warehouseId,
                client_id: "",
                date: "",
                products: []
            },
            actions:[
                {
                    name: this.$t('fulfillment_eon_returned.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillment_eon_returned.incidence'),
                    icon: {
                        color: 'accent',
                        icon: 'warning_amber'
                    },
                    link: '',
                },
                {
                    name: this.$t('fulfillment_eon_returned.restore'),
                    icon: {
                        color: 'accent',
                        icon: 'restore'
                    },
                    method: 'askRestore',
                },
            ]
        }
    },
    mounted(){
        this.$store.state.module = "headers.fulfillment_eon_returneds";
        this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_orders?status=RETURNED TO CHRONOS', {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => { 
                    x = x.attributes;   
                    x.createdAt = x.createdAt.substring(0,10);
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    // x['actions'][1].link = "/shipments/cod/incidence/" + x.id;
                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        
        showDetails(order){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.orderToShow = {
                    unique_order_number: response.data.order.unique_order_number,
                    created_at: response.data.order.created_at.substring(0,10),
                    shipping_service: response.data.order.shipping_service,
                    customer: response.data.order.name,
                    buyer: response.data.order.consignee_name,
                    products: JSON.parse(response.data.order.products).products
                }
                this.$store.state.loaderProps.visible = false;
                this.modalDetail = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        askRestore(order){
            this.modalRestore = true;
            this.orderToRestore = order;
        },
        restoreOrder(){
            this.$store.state.loaderProps.visible = true;
            if(this.orderToRestore != ''){
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'external_shipment/restore/' + this.orderToRestore.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200) {
                        this.orderToRestore = '';
                        this.modalRestore = false;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('fulfillments_cod_sent.restored')
                        }
                        this.index();
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: "error"
                }
            }
        },
        askUpload(){
            this.modalUploadIncidences = true;
        },
        uploadReturns(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile2);
            }
        },
        createInput2(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    this.readRows2 = result.split('\r\n');
                    this.sTHeaders2 = this.readRows2[0].toLowerCase().replace(' ', '').split(',');
                    this.readRows2.shift();
                    this.stepper2 = 2;
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveFile(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            var formData = new FormData();
            formData.append("file", this.inputFile2);
            this.$http.post(this.$store.state.chronosApi + 'external_shipment/return_orders', formData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: lsToken} } )
            .then( response => {
                this.$store.state.loaderProps.visible = false;
                this.responses = response.data
                this.stepper2 = 3;
            })
            .catch(error => {
                console.log(error)
                this.$store.state.loaderProps.visible = false;
            })
        },
        // uploadReturn(){
        //     const lsToken = localStorage.getItem("access_token");
        //     this.returned.client_id = this.$store.state.clientId;
        //     this.$http.post(this.$store.state.chronosApi + 'shipment/returnOrders', {returns: [this.returned]}, {headers: {Authorization: lsToken}} )
        //     .then( response => {
        //         console.log(response)
        //         this.$store.state.loaderProps.visible = false;
        //         this.modalUploadreturn = false;
        //         this.index();
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         this.$store.state.loaderProps.visible = false;
        //     })
        // }
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        }
    }
}
</script>

<style lang="scss">
    
</style>