<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_shipment.edit_order') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="shipment.shipping_service != 'OTRA'">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="shipment.shipping_service" :items="shippingServices" item-text="text" item-value="text" :label="$t('edit_shipment.shipping_service')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="px-5 py-0 my-0">
                            <v-col cols="6" justify="center" align="center"  class="py-0">
                                <v-autocomplete v-model="shipment.consignee.country" item-text="name" item-value="value" :label="$t('edit_shipment.country')" :rules="rules_required" :items="countries"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="shipment.shipping_service == 'OTRA'" class="px-5 py-0 my-0">
                            <v-col cols="6" justify="center" align="center" class="px-3 py-0 my-0">
                                <v-switch v-model="external_guide" color="azul" class="my-0">
                                    <template v-slot:label>
                                        {{$t("new_order.external_guide")}}
                                    </template>
                                </v-switch>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="shipment.shipping_service == 'OTRA'">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.shipment_number" :label="$t('edit_shipment.shipment_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row> -->
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="shipment.shipping_service == 'OTRA'">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.shipment_number" :label="$t('edit_shipment.shipment_number')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="external_guide == true && shipment.shipping_service == 'OTRA'" class="px-5 py-0 my-0">
                            <v-col cols="6" justify="center" align="center"  class="py-0">
                                <v-row class="px-0 mx-0 py-0 my-0">
                                    <v-file-input prepend-icon="" append-outer-icon="cloud_upload" @change="uploadLabel" @click="uploadFiles = true" class="cloud_upload py-0" multiple :clearable="true" v-model="shipment.file" :disabled="already_exists_label_file" accept=".png" :label="$t('edit_shipment.label')"></v-file-input>
                                    <v-btn outlined color="red" small class="px-0 ml-2 mt-2" style="min-width:25px !important"><v-icon :disabled="!already_exists_label_file" @click="deleteLabel()" small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="external_guide == true && shipment.shipping_service == 'OTRA'" class="px-5 py-0 my-0">
                            <v-col cols="6" justify="center" align="center"  class="py-0">
                                <v-row class="px-0 mx-0 py-0 my-0">
                                    <v-file-input prepend-icon="" append-outer-icon="cloud_upload" @change="uploadFile" class="cloud_upload py-0" @click="uploadFiles = true" multiple :clearable="true" v-model="shipment.attached_files" :disabled="already_exists_attached_file" accept=".pdf" :label="$t('new_order.attached_files')"></v-file-input>
                                    <v-btn outlined color="red" small class="px-0 ml-2 mt-2" style="min-width:25px !important"><v-icon :disabled="!already_exists_attached_file" @click="deleteAttachedFile()" small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="shipment.shipping_service == 'OTRA'" class="px-5 py-0 my-0">
                            <v-col cols="6" justify="center" align="center"  class="py-0">
                                <v-autocomplete v-model="shipment.external_shipping_service" item-text="text" item-value="value" :label="$t('new_order.shipping_service')" :rules="rules_required" :items="external_services"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="shipment.warehouse_id" item-value="id" item-text="name" :items="warehouses" :label="$t('edit_shipment.warehouse')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="order.order_type" :items="orderTypes" item-value="value" item-text="text" :label="$t('edit_shipment.order_type')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.invoice" :label="$t('edit_shipment.invoice')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.name" :label="$t('edit_shipment.buyers_name')" :rules="rules_name" counter="35"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.phone" :label="$t('edit_shipment.phone')" :rules="rules_phone"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.email" :label="$t('edit_shipment.email')" :rules="rules_email"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.zipcode" :label="$t('edit_shipment.zip_code')" :rules="rules_zipcode"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="is_local">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.neighborhood" :label="$t('edit_shipment.neighborhood')" :rules="is_local ? rules_required : []"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.street" :label="is_local? $t('edit_shipment.street') : $t('edit_shipment.address')" :rules="is_local ? rules_street : rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="is_local">
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.external_number" :label="$t('edit_shipment.n_ext')" :rules="is_local ? rules_external_number : []"></v-text-field>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.internal_number" :label="$t('edit_shipment.n_int')" :rules="is_local ? rules_internal_number : []"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.state" :label="$t('edit_shipment.state')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.municipality" :label="$t('edit_shipment.municipality')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.consignee.comments" :label="$t('edit_shipment.references')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="shipment.total_price" :label="$t('edit_shipment.total_cost')" :rules="shipment.cod == false ? rules_total_price : []"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="2" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product_selected.id" item-value="id" item-text="sku" :loading="products_load" :items="products" :label="$t('edit_shipment.product')"></v-autocomplete> 
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="product_selected.quantity" :label="$t('edit_shipment.quantity')"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="product_selected.unit_price" :label="$t('edit_shipment.unit_price')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0" justify="end" align="end">
                                <v-btn class="right white--text" color="amarillo" @click="addProduct" >
                                    {{ $t('edit_shipment.add') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <datatable :income_editable="true" :unit_price_editable="true" class="custom_table_class" :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel" >
                                {{ $t('create_appointment.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" >
                                {{ $t('create_appointment.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        client: function(){
            return this.$store.state.clientId
        },
        rules_required(){ return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        rules_number(){ return [
            v => v == null || /^(|\d)+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
            v => !!v || this.$t('create_appointment.validations.required_field')
        ]},
        rules_name(){ return [
            v => !!v || this.$t('create_appointment.validations.required_field'),
            a => a.length >= 3 && a.toString().length <= 35 || this.$t('edit_shipment.validations.name'),
        ]},
        rules_phone(){ return [
            b => b.length === 10 || this.$t('edit_shipment.validations.phone'),
            v => /^(\d*\.)?\d+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
        ]},
        rules_street(){ return [
            v => !!v || this.$t('create_appointment.validations.required_field'),
            v => v.toString().length <= 23 || this.$t('edit_shipment.validations.street'),
        ]},
        rules_external_number(){ return [
            v => !!v || this.$t('create_appointment.validations.required_field'),
            c => c.length <= 5 || this.$t('edit_shipment.validations.external_number'),
        ]},
        rules_internal_number(){ return [
            d => d == null || d.length <= 5 || this.$t('edit_shipment.validations.internal_number'),
        ]},
        rules_zipcode(){ return [
            e => e.length === 5 || this.$t('edit_shipment.validations.zipcode'),
            v => /^(\d*\.)?\d+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
        ]},
        rules_comments(){ return [
            v => (v.toString().length == 0 || (v.toString().length >= 3 && v.toString().length <= 255)) || this.$t('edit_shipment.validations.comments'),
        ]},
        rules_email(){ return [v => (v == null || v.length == 0 || /.+@.+\..+/.test(v)) || this.$t('edit_shipment.validations.email')]},
        rules_total_price(){ return [
            v => !!v || this.$t('create_appointment.validations.required_field'),
            v => /^(\d*\.)?\d+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
            v => parseFloat(v) <= 8000 || this.$t('edit_shipment.validations.total'),
        ]},
        headers(){ 
            return [
                { text: this.$t('edit_shipment.product'), value: 'product.sku', class: 'text-uppercase', width:'50%' },
                { text: this.$t('edit_shipment.quantity'), value: 'quantity', class: 'text-uppercase', width:'20%' },
                { text: this.$t('edit_shipment.unit_price'), value: 'unit_price', class: 'text-uppercase', width:'20%' },
                { text: '', value: 'actions', class: 'text-uppercase', width:'10%' },
            ]
        }
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            is_local: true,
            actions: [
                {
                    name: this.$t('edit_shipment.delete_product'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteProduct',
                }
            ],
            countries: [{name: this.$t('edit_shipment.mexico'), value: "mexico"} ,{name: this.$t('edit_shipment.usa'), value: "united states"}],
            id: this.$route.params.id,
            shippingServices: this.$store.state.shipping_services,
            orderTypes:[{ value: "cod", text: this.$t('edit_shipment.cod')},{ value: "prepaid", text: this.$t('edit_shipment.prepaid')}],
            props: {
                items: [],
                search: ''
            },
            products: [],
            product_selected: {
                sku: "",
                id: "",
                quantity: "",
                unit_price: "",
            },
            products_load: false,
            warehouses: [],
            uploadFiles: false,
            already_exists_label_file: false,
            already_exists_attached_file: false,
            external_guide: false,
            shipment: {
                shipping_service: '',
                shipment_number: '',
                unique_order_number: '',
                file: [],
                external_shipping_service: '',
                attached_files: [],
                warehouse_id: this.$store.state.warehouseId,
                cod: false,
                invoice: '',
                consignee: {
                    name: '',
                    phone: '',
                    country: '',
                    email: '',
                    neighborhood: '',
                    street: '',
                    internal_number: '',
                    external_number: '',
                    comments: '',
                    state: '',
                    municipality: '',
                    zipcode: ''    
                },
                order_details:[],
                total_price: ''
            },
            external_services: [
                "FedEx", 
                "UPS", 
                "DHL", 
                "PaqueteExpress", 
                "99 Minutos", 
                "Estafeta", 
                "Quiken", 
                "AFIMEX", 
                "Mercado Colecta", 
                "Tres Guerras"
            ],
            order: {
                order_type: '',
            },
        }
    },
    mounted(){
        this.index();
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + this.id, { headers: { Authorization: lsToken } })
            .then((response) => {
                response.data = response.data.data.attributes;
                this.products = [];
                this.shipment.unique_order_number = response.data.uniqueOrderNumber ?? '';
                this.shipment.shipping_service = response.data.shippingService ?? '';
                this.shipment.shipment_number = response.data.shipmentNumber ?? '';
                this.shipment.external_shipping_service = response.data.externalShippingService ?? '';
                this.shipment.invoice = response.data.invoice ?? '';
                this.$store.state.module = "headers." + this.shipment.shipping_service.toLowerCase() + "_cod_edit_pending_order";

                if(response.data.consignee.country.toLowerCase() != "mexico" && response.data.consignee.country.toLowerCase() != "méxico" && response.data.consignee.country.toLowerCase() != "mx") this.is_local = false;

                this.shipment.consignee.country = response.data.consignee.country.toLowerCase();
                this.shipment.consignee.name = response.data.consignee.name;
                this.shipment.consignee.zipcode = response.data.consignee.zipcode;
                this.shipment.consignee.phone = response.data.consignee.phone;
                this.shipment.consignee.email = response.data.consignee.email;
                this.shipment.consignee.neighborhood = response.data.consignee.neighborhood;
                this.shipment.consignee.street = response.data.consignee.street;
                this.shipment.consignee.external_number = response.data.consignee.externalNumber;
                this.shipment.consignee.internal_number = response.data.consignee.internalNumber;
                this.shipment.consignee.state = response.data.consignee.state;
                this.shipment.consignee.municipality = response.data.consignee.municipality;
                this.shipment.consignee.comments = response.data.consignee.comments;
                this.order.order_type = response.data.cod == false ? "prepaid" : "cod";
                this.shipment.total_price = response.data.total;
                this.props.items = [];
                response.data.shipmentProductDetail.data.forEach( x => {
                    x = x.attributes;
                    x.unit_price = x.unitPrice;
                    x.actions = JSON.parse(JSON.stringify(this.actions));
                    x.parameters = x.product.sku;
                    this.props.items.push(x);
                })

                // Get products by client
                this.products_load = true;
                this.$http.get(this.$store.state.chronosApi + 'productsAndKitsByClient/' + response.data.client.id, {headers: { Authorization: lsToken } })
                .then((response1) => {
                    response1.data = response1.data.data;
                    response1.data.forEach( x => {
                        this.products.push(x);
                    })
                    this.products_load = false;
                })
            
                // Get warehouses by client
                this.$http.get(this.$store.state.chronosApi + 'clientWarehouses?client_id=' + response.data.client.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data = response.data.data;
                    response.data.forEach( x => {
                        this.warehouses.push(x);
                    })
                }).catch((err) => {
                    console.log(err);
                })

                if(response.data.shippingService == "OTRA"){
                    this.$http.get(this.$store.state.chronosApi + 'shipment/getLabels?id=' + this.id, {headers: {Authorization: lsToken}})
                    .then((response) => {
                        if(response.data.data[0].data.length > 0){
                            this.shipment.file = new File(["foo"], "label.png", { type: "text/plain", }); 
                            this.already_exists_label_file = true;
                        }

                        if(response.data.data[0].files.length > 0){
                            this.file_id = response.data.data[0].files[0]._id;
                            this.shipment.attached_files = new File(["foo"], response.data.data[0].files[0].original_filename, { type: "text/plain", }); 
                            this.already_exists_attached_file = true;
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            const formVal = this.$refs.form.validate();
            if(this.shipment.shipping_service == "OTRA" && this.shipment.file == [] && this.shipment.attached_files == []) {
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    // text: this.$t('edit_shipment.success')
                    text: "Debe cargarse el archivo de etiqueta o archivo adjunto"
                }
                return;
            }
            if(formVal){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.shipment.order_details = [];
                this.props.items.forEach( x => {
                    delete x.id;
                    delete x.parameters;
                    delete x.actions;
                    if(x.product) x.sku = x.product.sku;
                    delete x.product;
                    if(x.unit_price != null) x.unitPrice = x.unit_price ;
                    else x.unitPrice = 1;
                    this.shipment.order_details.push(x);
                });
                
                this.shipment.cod = (this.order.order_type == "prepaid" ? false : true);

                delete this.shipment.file;
                delete this.shipment.attached_files;

                this.$http.patch(this.$store.state.chronosApi + 'shipment/update/' + this.id, this.shipment, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('edit_shipment.success')
                        }
                        this.$router.go(-1); 
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_shipment.form_invalid')
                }
            }
        },
        addProduct(){            
            if(this.product_selected.id != "" && this.product_selected.quantity != ""){
                // check if sku is already in list
                var exist =  false;
                this.props.items.forEach( x => {
                    if(x.id == this.product_selected.id) exist = true;
                })

                if(exist == true){
                    this.props.items.forEach( x => {
                        if(x.id == this.product_selected.id) x.quantity = parseInt(this.product_selected.quantity) + parseInt(x.quantity);
                    })
                }
                else {
                    const new_product = {
                        id: parseInt(this.product_selected.id),
                        quantity: parseInt(this.product_selected.quantity),
                        unit_price: this.product_selected.unit_price,
                        actions: JSON.parse(JSON.stringify(this.actions)),
                        parameters: this.products.find(x => x.id == this.product_selected.id).sku,
                        product: {
                            sku: this.products.find(x => x.id == this.product_selected.id).sku,
                        }
                    }

                    this.props.items.push(new_product);
                }

                this.product_selected.sku = "";
                this.product_selected.id = "";
                this.product_selected.quantity = "";
                this.product_selected.unit_price = "";
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_shipment.product_error')
                }
            }
        },
        deleteProduct(sku){
            const index = this.props.items.findIndex(x => x.product.sku === sku);
            this.props.items.splice(index, 1);
        },
        deleteLabel(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'shipment/remove_label?shipment_id=' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.index();
                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        uploadLabel(){
            if( this.uploadFiles == false) return;
            this.uploadFiles = false;

            const lsToken = localStorage.getItem("access_token");
            const formData = new FormData();
            if(this.shipment.shipment_number.length > 0){
                this.$store.state.loaderProps.visible = true;
                formData.append('label', this.shipment.file[0]);
                formData.append('shipment_number', this.shipment.shipment_number);

                // console.log([...formData]) // Print form data displaying all pr

                this.$http.post(this.$store.state.chronosApi + 'shipment/add_label?shipment_id=' + this.id, formData, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response;
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_shipment.error')
                }
            }
        },
        uploadFile(){
            if( this.uploadFiles == false) return;
            this.uploadFiles = false;

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const formData = new FormData();
            console.log(this.shipment.attached_files);
            formData.append('files[]', this.shipment.attached_files[0]);

            this.$http.post(this.$store.state.chronosApi + 'shipment/files/store?shipment_id=' + this.id, formData, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                console.log(response);
                this.index();
                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        }, 
        deleteAttachedFile(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'shipment/files/delete?file_id=' + this.file_id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.index();
                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        'shipment.consignee.country': function() {
            if(this.shipment.consignee.country == "mexico") this.is_local = true;
            else this.is_local = false;
        }
    }
}
</script>

<style lang="css">
</style>