<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="3" class="py-0 my-0">
                    <client-selector :addAllOption="false" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :only_fulfillment="1" :is_national="2" :cod="1"></client-selector>  
                </v-col>
                <v-col cols="2" class="py-0 my-0">
                    <v-autocomplete class="mt-0 my-0 py-0" v-model="year" :items="years" :label="$t('closing_minute.year')"></v-autocomplete> 
                </v-col>
                <v-col cols="2" class="py-0 my-0">
                    <v-autocomplete class="mt-0 my-0 py-0" v-model="month" :items="months" item-value="value" item-text="name" :label="$t('closing_minute.month')"></v-autocomplete> 
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('stock_report.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>

                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('closing_minute.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="pt-0 pb-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers: headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container> 
        <search-dialog :uon="current_uon" v-if="current_uon != ''" class="show_search_modal"></search-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('closing_minute.order'), align: "center", value: 'unique_order_number', class: 'text-uppercase' },
                { text: this.$t('closing_minute.tracking_number'), align: "center", value: 'shipment_number', class: 'text-uppercase' },
                { text: this.$t('closing_minute.parcel'), align: "center", value: 'shipping_service', class: 'text-uppercase' },
                { text: this.$t('closing_minute.state'), align: "center", value: 'state', class: 'text-uppercase' },
                { text: this.$t('closing_minute.cod'), align: "center", value: 'total', class: 'text-uppercase' },
                { text: this.$t('closing_minute.status'), align: "center", value: 'last_status', class: 'text-uppercase' },
                { text: this.$t('closing_minute.pickup'), align: "center", value: 'pickup', class: 'text-uppercase' },
                { text: this.$t('closing_minute.last_updated'), align: "center", value: 'last_updated_at', class: 'text-uppercase' },
                { text: this.$t('closing_minute.collected'), align: "center", value: 'status', class: 'text-uppercase' },
                { text: this.$t('closing_minute.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            months: [
                { value: '01', name: this.$t('closing_minute.january')},
                { value: '02', name: this.$t('closing_minute.february')},
                { value: '03', name: this.$t('closing_minute.march')},
                { value: '04', name: this.$t('closing_minute.april')},
                { value: '05', name: this.$t('closing_minute.may')},
                { value: '06', name: this.$t('closing_minute.june')},
                { value: '07', name: this.$t('closing_minute.july')},
                { value: '08', name: this.$t('closing_minute.august')},
                { value: '09', name: this.$t('closing_minute.september')},
                { value: '10', name: this.$t('closing_minute.october')},
                { value: '11', name: this.$t('closing_minute.november')},
                { value: '12', name: this.$t('closing_minute.december')},
            ],
            month: "",
            year: new Date().getFullYear(),
            years: this.$store.state.years_catalog,
            show_export_options: false,
            actions: [
                {
                    name: this.$t('closing_minute.detail'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showInfo',
                    permission: "closing_minute.index"
                }
            ],
            showSearch: false,
            current_uon: ""
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.closing_minute";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
        },
        generate(){
            this.props.items = [];
            this.summary = {
                shipments: 0,
                cod: 0,
                deliveries: 0,
                cod_deliveries: 0,
                closing_minute: 0,
            };
            
            if(this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'trackNTrace/closing_minute?client_id='+ this.$store.state.clientId + '&month='+ this.month + '&year=' + this.year , {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data.data.forEach(x => {
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x["parameters"] = x.unique_order_number;
                        x["total"] = "$" + this.numberFormat.format(x.total);
                        this.props.items.push(x); 
                    });
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    console.log(response)
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataExcel(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;
                
                this.$http.get(this.$store.state.chronosApi + 'trackNTrace/closing_minute_export?client_id='+ this.$store.state.clientId + '&month='+ this.month + '&year=' + this.year, {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'closing_minute.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataPdf(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                this.$http.get(this.$store.state.chronosApi + 'reports/operationsByClientPdf?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Operaciones_Por_Cliente.pdf');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        showInfo(uon){
            this.current_uon = uon;
            this.showSearch = true;
        },
    },
    watch: {
        '$store.state.clientId': function(){
            this.props.items = [];
        }
    }
}
</script>

<style>
</style>